import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSection from '../../components/NewsSection'
import SEO from '../../components/seo'
import * as s from '../../pages/news.module.scss'

const SignalTools: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAL TOOLS делится на Lite и PRO"
        description="Модуль TOOLS делится на TOOLS Lite и TOOLS PRO. TOOLS Lite будет поставляться вместе с DASHBOARD, а TOOLS PRO будет иметь отдельную авторизацию и поставляться отдельно. Всем пользователям текущих TOOLS и DASHBOARD (и тем кто купит до 31.12.2022) версия TOOLS PRO будет также доступна на все время действия лицензий."
      />
      <NewsSection
        title="SIGNAL TOOLS делится на Lite и PRO"
        dateData={{ date: '17.11.2022' }}
      >
        <p className={s.text}>
          Важная новость для пользователей модуля SIGNAL TOOLS!
        </p>
        <p className={s.text}>
          Модуль TOOLS делится на TOOLS Lite и TOOLS PRO. TOOLS Lite будет
          поставляться вместе с DASHBOARD, а TOOLS PRO будет иметь отдельную
          авторизацию и поставляться отдельно. Всем пользователям текущих TOOLS
          и DASHBOARD (и тем кто купит до 31.12.2022) версия TOOLS PRO будет
          также доступна на все время действия лицензий.
        </p>
        <p className={s.text}>
          После 01.01.2023 мы будем продавать TOOLS PRO по новой цене.
        </p>
        <p className={s.text}>
          В течение этого года модуль TOOLS развивался и уже существенно вышел
          за рамки сервиса для подготовки данных в DASHBOARD. Сейчас TOOLS стал
          полноценным инструментом для работы в BIM на строительной площадке. В
          нем появился функционал по проверке модели - Checker, работа с
          BCF-замечаниями, публикация моделей в BIM-Link, Экспорт в IFC,
          привязка документов к элементам модели. Также дорабатывается
          функционал по созданию сетевых и календарных графиков.
        </p>
        <p className={s.text}>
          Мы приняли решение повысить стоимость модуля TOOLS PRO с 1 января 2023
          года.Но чтобы это не повлияло на стоимость DASHBOARD, мы разделили
          модуль TOOLS на 2 части - TOOLS Lite, который будет продолжать входить
          в DASHBOARD и TOOLS PRO, который будет продаваться отдельно. Стоимость
          DASHBOARD, INSPECTION и VALUE пока не планируется корректировать.
        </p>
        <StaticImage
          className={s.imageBlock}
          src="../../assets/images/news/2022-11-17-signal-tools/image-1.png"
          alt="SIGNAL TOOLS"
          title="SIGNAL TOOLS"
          placeholder="blurred"
        />
        <h2 className={s.textTitle}>Функционал версий Lite и PRO</h2>
        <p className={s.text}>
          Модуль TOOLS Lite входит в лицензию DASHBOARD и поставляется вместе с
          ним. Он содержит в себе функционал, необходимый для ведения
          строительной модели и подготовки данных для публикации в личный
          кабинет заказчика (DASHBOARD). TOOLS Lite включает в себя команды для
          получения объемов, отметки выполненных работ и подготовки фото360 по
          проектной модели.
        </p>
        <div className="mt-8 mb-8">
          <p>TOOLS For Revit Lite</p>
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-3.png"
            alt="TOOLS For Revit Lite"
            title="TOOLS For Revit Lite"
            placeholder="blurred"
          />
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-2.png"
            alt="TOOLS For Revit Lite 1"
            title="TOOLS For Revit Lite 1"
            placeholder="blurred"
          />
          <p>TOOLS For Navis Lite</p>
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-4.png"
            alt="TOOLS For Navis Lite"
            title="TOOLS For Navis Lite"
            placeholder="blurred"
          />
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-5.png"
            alt="TOOLS For Navis Lite 1"
            title="TOOLS For Navis Lite 1"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Модуль TOOLS PRO имеет отдельную авторизацию и покупается отдельно от
          DASHBOARD.
        </p>
        <p className={s.text}>
          Приложение на Revit включает в себя помимо функционала TOOLS Lite еще
          команды по подгрузке результатов проверки с помощью Checker,
          инструменты для Классификации моделей и инструменты для создания
          исполнительных моделей (моделирования по облакам точек лазерного
          сканирования).
        </p>
        <div className="mt-8 mb-8">
          <p>TOOLS For Revit PRO</p>
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-6.png"
            alt="TOOLS For Revit PRO"
            title="TOOLS For Revit PRO"
            placeholder="blurred"
          />
          <StaticImage
            className="mt-2"
            src="../../assets/images/news/2022-11-17-signal-tools/image-7.png"
            alt="TOOLS For Revit PRO 1"
            title="TOOLS For Revit PRO 1"
            placeholder="blurred"
          />
        </div>
        <p className={s.text}>
          Приложение на Navisworks включает в себя помимо функционала TOOLS Lite
          еще команды по проверке моделей (Checker), Привязку замечаний, Issues
          из BIM360 и файлов к элементам модели, управление BCF-замечаниями,
          Генерацией поисковых наборов по различным правилам и разнообразными
          экспортами данных из модели. Также TOOLS PRO включает в себя Экспорт в
          IFC и публикацию в BIM-Link, в перспективе появится генератор
          календарного графика работ по сетевому графику.
        </p>
        <div className="mt-8">
          <p>TOOLS For Navis PRO</p>
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-8.png"
            alt="TOOLS For Navis PRO"
            title="TOOLS For Navis PRO"
            placeholder="blurred"
          />
          <StaticImage
            src="../../assets/images/news/2022-11-17-signal-tools/image-9.png"
            alt="TOOLS For Navis PRO 1"
            title="TOOLS For Navis PRO 1"
            placeholder="blurred"
          />
          <StaticImage
            className="mt-8"
            width={992}
            src="../../assets/images/news/2022-11-17-signal-tools/image-10.png"
            alt="TOOLS For Navis PRO 2"
            title="TOOLS For Navis PRO 2"
            placeholder="blurred"
          />
        </div>
      </NewsSection>
    </>
  )
}

export default SignalTools
